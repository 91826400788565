<template>

         <NavBar>
                    <template v-slot:navHeader></template>
                    <template v-slot:navForm>
                        <div >
                            <ul class="m-0 me-5 nav-item">
                                <li class=" my-auto " style="list-style-type: none;">
                                    <div class="nav-item dropdown ">
                                        <a class="nav-link dropdown-toggle fs-5 text-white" href="#" id="dropdownMenuButton1" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            <i class="bi bi-person-fill me-1" aria-label="Account options"></i>{{adminName}}
                                        </a>
                                        <ul class="dropdown-menu me-2 mt-3" aria-labelledby="dropdownMenuButton1">
                                            <li><a href="#" class="dropdown-item dropColor fs-5"><i class="bi bi-person-circle me-2 mx-3" aria-label="Account"></i>Account</a></li>
                                            <li><a href="/registration" class="dropdown-item dropColor fs-5"><i class="bi bi-files  me-2 mx-3" aria-label="Administration"></i>Administration</a></li>
                                            <li><a href="/faq" class="dropdown-item dropColor fs-5"><i class="bi bi-question-circle me-2 mx-3" aria-label="FAQ"></i>FAQ</a></li>
                                            <li><hr class="dropdown-divider"></li>
                                            <li><a href="#backDrop" class="dropdown-item dropColor fs-5" data-bs-toggle="modal"><i class="bi bi-box-arrow-right  me-2 mx-3" aria-label="Log out"></i>Log out</a></li>
                                        </ul>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </template>
                    <template v-slot:navModals>
                        <div class="modal fade" id="backDrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                            <div class="modal-dialog">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title" id="staticBackdropLabel">Log Out</h5>
                                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div class="modal-body">
                                        <strong>Are you sure you want to log out?</strong>
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn" data-bs-dismiss="modal">Close</button>
                                        <button type="button" class="btn" @click="KClogout()" data-bs-dismiss="modal">Log Out</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </NavBar>
<div class="container-fluid" style="min-height:100vh">               
        <div class="row">
                 <SideBar style="min-height:100vh">
                <template v-slot:sbHeader>
                </template>
                <template v-slot:sbItems>
                    <div class="" >
                        <a class="sbItem text-light " href="/dashboard"><i class="bi bi-clipboard"></i> &nbsp;Dashboard</a>
                        <a class="sbItem text-light" href="/devices-overview"><i class="bi bi-display"></i>&nbsp; Devices</a>
                        <a class="sbItem text-light" href="/campagnes-overview"><i class="bi bi-card-list"></i>&nbsp; Campaigns</a>
                        <a class="sbItem text-light" href="/select-show"><i class="bi bi-collection-play"></i>&nbsp; Preview</a>
                    </div>
                </template>
            </SideBar>
                   
        <div class="col" >
            <div id="page-content-wrapper">
                <!-- <Navbar /> -->
               
           
                <div class="container-fluid" style="height: 100%;">
                    <router-view/>
                </div>
            </div>
        </div>
        </div>
        
    </div>
</template>

<script>
import Navbar from '@/components/layout/Navbar'
import Sidebar from '@/components/layout/Sidebar'
export default {
    name: 'Narrowcasting', 
    components: {
      Navbar,
      Sidebar,
    },

    data(){
        return{
            adminName: ''
        }
    },
    computed: {
        getData(){
            if (!this.$store.state.user.isAuthenticated){
                this.$router.push('/')
            }
        }
    },

    mounted(){
        this.getAdminInfo()
    },

    methods: {
         KClogout(){
                this.$store.dispatch('key/logout')
                console.log("in out of here")
                this.$router.push("/")
            },
           async getAdminInfo(){
            this.adminName = localStorage.adminName
                await this.$store.dispatch("getAllLocations")
                await this.$store.dispatch("getAll", {type:"device"})
                await this.$store.dispatch("getAll", {type:"schedule"})
            },
        addActive(){
            var element = document.getElementById("myDIV");
            var btns = element.getElementsByClassName("sbItem");
            
            for(var i=0; i< btns.length; i++){
                btns[i].addEventListener("click", function(){
                    var current = document.getElementsByClassName("active");
                    current[0].className = current[0].className.replace(" active", "");
                    this.className += "active";
                })
            }
            
       },
    }
}
</script>

<style>
#newLogo{
    background-color: rgba(63, 63, 63, 1) ;
}
/* .a-active{
    background-color: #1AA5D3 !important;
} */

.sidebar a {
  display: block;
  color: black;
  padding: 16px;
  text-decoration: none;
}

 
.active, .sbItem:hover {
  background-color: #f7960b;
}

/* .sidebar a:hover {
  background-color: #f7960b;
  color: white;
} */

.activeStyle{
    background-color: #f7960b;
}

a:hover{
    text-decoration: none;
}
.dropColor:hover{
    background-color: #067db6;
}

.sbItem{
    font-size: 15px;
}
.scaleHeight{
    height: 100%;
}

</style>